import React from 'react'
import { NavLink } from 'react-router-dom'

import { Box, Text, Flex, Stack, Image, Divider } from '@chakra-ui/react'

import LogoFNDE from '@/assets/svg/logo-fnde.svg'
import LogoLIP from '@/assets/svg/logo-lip-w.svg'

import NavItem from './parts/NavItem'

const Sidebar = () => {
  return (
    <Stack
      p={14}
      gap={6}
      bg="brand.primary.dark_2"
      color="white"
      h="full"
      maxW={{
        base: 'full',
        lg: '27.5rem',
      }}
      position={{ base: 'unset', lg: 'fixed' }}
    >
      <Flex>
        <Image src={LogoLIP} alt="Logo LIP" h="36px" />
        <Divider orientation="vertical" mx={4} opacity={1} />
        <Image src={LogoFNDE} alt="Logo FNDE" h="36px" />
      </Flex>
      <Text fontWeight="medium" fontSize="5xl">
        Fale conosco
      </Text>
      <Text fontSize="md">
        Possui alguma dúvida?
        <br /> Entre em contato com nossa equipe de suporte para receber
        assistência.
      </Text>
      <Stack>
        <NavItem name="Formulário de contato" to="/contato" />
        <NavItem name="Tirar dúvidas - FAQ" to="/duvidas" />
        <NavItem name="Ler manual" to="/manual" />
        <NavItem name="Vídeos tutoriais" to="/tutoriais" />
      </Stack>
    </Stack>
  )
}

export default Sidebar
