import React from 'react'
import InputMask from 'react-input-mask'

import { Field, ErrorMessage, FieldProps } from 'formik'

import {
  Text,
  FormControl,
  FormLabel,
  FormHelperText,
  Input as ChakraInput,
} from '@chakra-ui/react'

import { IInputProps } from './types'

export const InputWithMask = ({
  name,
  label,
  mask,
  type,
  placeholder,
  helperText,
  inputProps,
  ...rest
}: IInputProps) => {
  return (
    <FormControl {...rest}>
      <FormLabel fontSize="sm" fontWeight="semibold">
        {label}
      </FormLabel>
      <Field name={name}>
        {({ field, form }: FieldProps) => (
          <ChakraInput
            as={InputMask}
            mask={mask}
            variant="filled"
            bg="brand.neutral.light_2"
            fontSize="sm"
            type={type}
            placeholder={placeholder}
            _focusVisible={{ bg: 'gray.100' }}
            {...inputProps}
            onChange={e => form.setFieldValue(field.name, e.target.value)}
          />
        )}
      </Field>
      <FormHelperText>{helperText}</FormHelperText>
      <ErrorMessage name={name}>
        {(message: string) => (
          <Text color="red.600" fontSize="sm">
            {message}
          </Text>
        )}
      </ErrorMessage>
    </FormControl>
  )
}
