import React, { useMemo } from 'react'
import { FaSearch } from 'react-icons/fa'

import {
  Text,
  Stack,
  Input,
  InputGroup,
  InputRightElement,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'

import faqJson from '@/data/faq.json'

import { IFaqSection } from './types'

const Faq = () => {
  const [searchValue, setSearchValue] = React.useState('')
  const faqSections: Array<IFaqSection> = useMemo(() => {
    const searchTerm = searchValue.toLowerCase()

    const filteredData = faqJson
      .map(subject => {
        const filteredQuestions = subject.questions.filter(
          question =>
            question.title.toLowerCase().includes(searchTerm) ||
            question.answer.toLowerCase().includes(searchTerm),
        )

        return {
          ...subject,
          questions: filteredQuestions,
        }
      })
      .filter(subject => subject.questions.length > 0)

    return filteredData
  }, [searchValue])

  const handleSearch = (value: string) => {
    setSearchValue(value)
  }

  return (
    <Stack gap={7}>
      <Stack gap={4}>
        <Text
          textAlign="center"
          fontSize="2rem"
          fontWeight="bold"
          color="brand.primary.dark_2"
        >
          Tirar dúvidas - FAQ
        </Text>
        <Text textAlign="center" fontSize="md" fontWeight="semibold">
          Bem-vindo/a! Essa é a sessão de Perguntas e Respostas frequentes do
          LIP do PNLD. É possível que sua dúvida já esteja respondida por aqui!
          😉
        </Text>
        <InputGroup>
          <Input
            value={searchValue}
            variant="filled"
            placeholder="Digite aqui a sua dúvida...."
            _focusVisible={{ bg: 'gray.100' }}
            onChange={e => handleSearch(e.target.value)}
          />
          <InputRightElement>
            <FaSearch color="brand.primary.dark_1" />
          </InputRightElement>
        </InputGroup>
      </Stack>
      {faqSections.length > 0 ? (
        faqSections.map((section, index) => (
          <Stack gap={2} key={`faq-section-${index + 1}`}>
            <Text
              fontWeight="semibold"
              fontSize="2xl"
              color="brand.primary.dark_1"
            >
              {section.subject}
            </Text>
            <Accordion allowToggle>
              <Stack spacing={2}>
                {section.questions.map((question, index) => (
                  <AccordionItem key={index}>
                    <AccordionButton>
                      <Text>{question.title}</Text>
                      <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel as={Stack} p={0} gap={0}>
                      <Text fontSize="sm" p={2} pl={4}>
                        {question.answer}
                      </Text>
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Stack>
            </Accordion>
          </Stack>
        ))
      ) : (
        <Text textAlign="center" fontSize="md" fontWeight="semibold">
          Nenhuma pergunta frequente encontrada.
        </Text>
      )}
    </Stack>
  )
}

export default Faq
