import React from 'react'
import { FaUpload } from 'react-icons/fa'

import { useFormikContext, FormikValues } from 'formik'

import { Flex, Text, Icon, Input, useToast } from '@chakra-ui/react'

import ViewFile from './parts/ViewFIle'
import { IFileUploadProps } from './types'

export const FileUpload = ({
  id,
  name,
  helperText,
  acceptedFileTypes = [],
  maxFileSize = 1,
  isMultiple = false,
}: IFileUploadProps) => {
  const toast = useToast()

  const { values, setValues } = useFormikContext<FormikValues>()
  const fileInputRef = React.useRef<HTMLInputElement>(null)

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleAddFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files

    if (files) {
      addNewFiles(files)

      event.target.value = ''
    }
  }

  const handleRemoveFile = (file: File) => {
    if (isMultiple) {
      const files = values[name] as Array<File>
      const filteredFiles = files.filter(f => f.name !== file.name)

      setValues({
        ...values,
        [name]: filteredFiles.length > 0 ? filteredFiles : '',
      })
    } else {
      setValues({
        ...values,
        [name]: '',
      })
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation()
    event.preventDefault()

    const files = event.dataTransfer.files

    if (files) {
      addNewFiles(files)
    }
  }

  const addNewFiles = (files: FileList) => {
    const filesArray = Array.from(files)
    const filesSize = filesArray.reduce((acc, file) => acc + file.size, 0)

    if (filesSize > maxFileSize * Math.pow(10, 6)) {
      toast({
        title: 'Erro ao adicionar arquivo',
        description: `O arquivo deve ter até ${maxFileSize}MB`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } else {
      if (isMultiple) {
        setValues({
          ...values,
          [name]: values[name] ? [...values[name], ...filesArray] : filesArray,
        })
      } else {
        setValues({
          ...values,
          [name]: filesArray[0],
        })
      }
    }
  }

  return (
    <Flex direction="column" align="left" gap={2}>
      {!values[name] || isMultiple ? (
        <Flex
          align="center"
          gap={4}
          paddingY={3}
          paddingX={4}
          bg="brand.neutral.light_2"
          border="2px dashed"
          borderRadius="lg"
          borderColor="brand.primary.dark_2"
          cursor="pointer"
          onClick={openFileDialog}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <Icon as={FaUpload} color="brand.primary.dark_2" />
          <Text
            color="brand.primary.dark_2"
            fontSize="sm"
            fontWeight="medium"
            fontStyle="italic"
          >
            Clique aqui para selecionar
            {isMultiple ? ' os arquivos' : ' o arquivo'}
          </Text>
        </Flex>
      ) : (
        <ViewFile
          file={values[name] as File}
          onRemove={() => handleRemoveFile(values[name] as File)}
        />
      )}
      <Input
        id={id}
        name={name}
        ref={fileInputRef}
        type="file"
        accept={acceptedFileTypes.join(',')}
        multiple={isMultiple}
        onChange={handleAddFiles}
        display="none"
      />
      <Flex justify="space-between" align="center">
        <Text fontSize="sm" color="brand.neutral.dark_1">
          Até {maxFileSize}MB
        </Text>
        <Text fontSize="sm" color="brand.neutral.dark_1">
          {helperText}
        </Text>
      </Flex>
      {isMultiple && values[name]?.length > 0 && (
        <Flex direction="column" gap={2}>
          {(values[name] as Array<File>)?.map(file => (
            <ViewFile
              key={file.name}
              file={file}
              onRemove={() => handleRemoveFile(file)}
            />
          ))}
        </Flex>
      )}
    </Flex>
  )
}
