import axios from 'axios'

const http = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/apirest.php/`,
})

http.interceptors.request.use(config => {
  const user = process.env.REACT_APP_USER
  const password = process.env.REACT_APP_PASSWORD
  const appToken = process.env.REACT_APP_API_APP_TOKEN

  config.headers['App-Token'] = appToken

  if (user && password) {
    config.auth = {
      username: user,
      password: password,
    }
  }
  return config
})

export default http
