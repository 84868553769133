import { AxiosError } from 'axios'

import { UseMutationOptions, useMutation } from '@tanstack/react-query'

import { postOpenTicket } from './services'
import { TPostDefaultResponse, TPostOpenTicketParams } from './types'

export const useMutationPostTicket = (
  options?: UseMutationOptions<
    TPostDefaultResponse,
    AxiosError,
    TPostOpenTicketParams
  >,
) => {
  const mutationFunction = (params: TPostOpenTicketParams) =>
    postOpenTicket(params)

  return useMutation<TPostDefaultResponse, AxiosError, TPostOpenTicketParams>({
    mutationFn: mutationFunction,
    ...options,
  })
}
