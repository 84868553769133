import http from '@/api/http'
import {
  TGetInitSessionResponse,
  TPostTicketParams,
  TPostInsertTicketDocumentParams,
  TPostDefaultResponse,
  TPostOpenTicketParams,
} from '@/api/types'
import { zipFiles } from '@/utils/file'

export const getInitSession = async () => {
  const response = await http.get<TGetInitSessionResponse>('/initSession/')

  return response.data
}

export const postTicket = async (
  data: TPostTicketParams,
  sessionToken: string,
) => {
  const response = await http.post<TPostDefaultResponse>(
    '/Ticket/',
    {
      input: {
        requesttypes_id: 1,
        _users_id_requester: 802,
        _groups_id_assign: 57,
        ...data,
      },
    },
    {
      headers: {
        'Session-Token': sessionToken,
      },
    },
  )

  return response.data
}

export const postUploadFile = async (data: FormData, sessionToken: string) => {
  const response = await http.post<TPostDefaultResponse>('/Document/', data, {
    headers: {
      'Session-Token': sessionToken,
    },
  })

  return response.data
}

export const postInsertTicketDocument = async (
  data: TPostInsertTicketDocumentParams,
  sessionToken: string,
) => {
  const response = await http.post<TPostDefaultResponse>(
    '/Document/1/Document_Item/',
    {
      input: {
        ...data,
        itemtype: 'Ticket',
      },
    },
    {
      headers: {
        'Session-Token': sessionToken,
      },
    },
  )

  return response.data
}

export const postOpenTicket = async (data: TPostOpenTicketParams) => {
  const { session_token: sessionToken } = await getInitSession()

  const ticketResponse = await postTicket(
    {
      name: data.name,
      content: data.content,
    },
    sessionToken,
  )

  const tickedId = ticketResponse.id

  if (data.files.length > 0) {
    const zipFile = new Blob([await zipFiles(data.files)], {
      type: 'application/zip',
    })

    const formData = new FormData()
    formData.append(
      'uploadManifest',
      JSON.stringify({
        input: {
          name: 'Anexos',
          _filename: ['arquivo'],
        },
      }),
    )
    formData.append('filename', zipFile, 'arquivo.zip')

    const { id: documentId } = await postUploadFile(formData, sessionToken)

    const attachFilesResponse = await postInsertTicketDocument(
      {
        documents_id: documentId,
        items_id: tickedId,
      },
      sessionToken,
    )

    return attachFilesResponse
  }

  return ticketResponse
}
