import React from 'react'

import { Field, ErrorMessage, FormikValues, useFormikContext } from 'formik'

import {
  Text,
  FormControl,
  FormLabel,
  FormHelperText,
  Textarea as ChakraTextarea,
  Flex,
} from '@chakra-ui/react'

import { ITextareaProps } from './types'

export const Textarea = ({
  name,
  label,
  type,
  placeholder,
  helperText,
  maxLength,
  inputProps,
  ...rest
}: ITextareaProps) => {
  const { values, setValues } = useFormikContext<FormikValues>()
  const [count, setCount] = React.useState(0)

  return (
    <FormControl {...rest}>
      <FormLabel fontSize="sm" fontWeight="semibold">
        {label}
      </FormLabel>
      <Field
        as={ChakraTextarea}
        variant="filled"
        bg="brand.neutral.light_2"
        fontSize="sm"
        name={name}
        type={type}
        placeholder={placeholder}
        maxLength={maxLength}
        _focusVisible={{ bg: 'gray.100' }}
        minH="8rem"
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setValues({
            ...values,
            [name]: e.target.value,
          })
          setCount(e.target.value.length)
        }}
        {...inputProps}
      />
      <Flex justify="space-between" align="flex-end">
        <FormHelperText>{helperText}</FormHelperText>
        {maxLength && (
          <Text fontSize="xs" color="gray.500">
            {count}/{maxLength}
          </Text>
        )}
      </Flex>
      <ErrorMessage name={name}>
        {(message: string) => (
          <Text color="red.600" fontSize="sm">
            {message}
          </Text>
        )}
      </ErrorMessage>
    </FormControl>
  )
}
