import { accordionAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys)

const baseStyle = definePartsStyle({
  container: {
    bg: 'brand.neutral.light_2',
    borderBottom: '1px solid',
    borderBottomColor: 'brand.neutral.light_1',
  },
  button: {
    fontWeight: 'semibold',
    fontSize: 'md',
    color: 'brand.neutral.dark_2',
    justifyContent: 'space-between',
    p: 4,
  },
  icon: {
    boxSize: 7,
    color: 'brand.primary.dark_1',
  },
})

export const accordionTheme = defineMultiStyleConfig({ baseStyle })
