import JSZip from 'jszip'

export const zipFiles = async (files: FileList) => {
  const zip = new JSZip()
  Array.from(files).forEach(file => {
    zip.file(file.name, file)
  })

  return await zip.generateAsync({ type: 'blob' })
}
