import React, { useMemo } from 'react'
import { FaSearch } from 'react-icons/fa'

import {
  Box,
  Flex,
  Stack,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  useMediaQuery,
} from '@chakra-ui/react'

import tutorialsVideosJson from '@/data/tutorials-videos.json'

import { ITutorialsSection } from './types'

const Tutorials = () => {
  const [searchValue, setSearchValue] = React.useState('')
  const tutorialsSection: Array<ITutorialsSection> = useMemo(() => {
    const searchTerm = searchValue.toLowerCase()

    const filteredData = tutorialsVideosJson
      .map(subject => {
        const filteredVideos = subject.videos.filter(
          video =>
            video.title.toLowerCase().includes(searchTerm) ||
            video.description.toLowerCase().includes(searchTerm),
        )

        return {
          ...subject,
          videos: filteredVideos,
        }
      })
      .filter(subject => subject.videos.length > 0)

    return filteredData
  }, [searchValue])
  const [isMobile] = useMediaQuery('(max-width: 992px)')

  const handleSearch = (value: string) => {
    setSearchValue(value)
  }

  return (
    <Stack gap={7}>
      <Stack gap={4}>
        <Text
          textAlign="center"
          fontSize="2rem"
          fontWeight="bold"
          color="brand.primary.dark_2"
        >
          Vídeos tutoriais
        </Text>
        <Text textAlign="center" fontSize="md" fontWeight="semibold">
          Bem-vindo/a! Esses são os vídeos tutoriais do LIP do PNLD. Eles foram
          feitos para apoiar seu uso. Aqui você poderá ver os vídeos quantas
          vezes precisar.
        </Text>
        <InputGroup>
          <Input
            value={searchValue}
            variant="filled"
            placeholder="Digite aqui a sua dúvida...."
            _focusVisible={{ bg: 'gray.100' }}
            onChange={e => handleSearch(e.target.value)}
          />
          <InputRightElement>
            <FaSearch color="brand.primary.dark_1" />
          </InputRightElement>
        </InputGroup>
      </Stack>
      {tutorialsSection.length > 0 ? (
        tutorialsSection.map((section, index) => (
          <Stack gap={4} key={`tutorial-section-${index + 1}`} mb={4}>
            <Text
              fontWeight="semibold"
              fontSize="2xl"
              color="brand.primary.dark_1"
            >
              {section.subject}
            </Text>
            {section.videos.map((video, index) => (
              <Flex
                key={`tutorial-${section.subject}-${index + 1}`}
                gap={7}
                direction={{
                  base: 'column-reverse',
                  lg: 'row',
                }}
              >
                <iframe
                  width={isMobile ? '100%' : ''}
                  src={`https://player.vimeo.com/video/${video.id}`}
                  title={video.title}
                  allowFullScreen
                />
                <Stack>
                  <Text
                    fontWeight="semibold"
                    fontSize="2xl"
                    color="brand.primary.dark_1"
                  >
                    {video.title}
                  </Text>
                  <Text fontSize="sm" color="brand.neutral.dark_2">
                    {video.description}
                  </Text>
                </Stack>
              </Flex>
            ))}
          </Stack>
        ))
      ) : (
        <Text textAlign="center" fontSize="md" fontWeight="semibold">
          Nenhum vídeo encontrado
        </Text>
      )}
    </Stack>
  )
}

export default Tutorials
