import React, { useMemo } from 'react'
import { FaLaptop, FaMobileAlt } from 'react-icons/fa'

import { Box, Flex, Stack, Text, Icon, Button } from '@chakra-ui/react'

import pdf_mobile from '@/assets/pdf/manual_mobile.pdf'
import pdf_desktop from '@/assets/pdf/manual_desktop.pdf'

const Manual = () => {
  // const isMobile = useMemo(() => {
  //   const regex =
  //     /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
  //   return regex.test(navigator.userAgent)
  // }, [])

  // Solução temporária enquanto não tivermos o manual para mobile
  const isMobile = false

  const downloadManual = () => {
    const link = document.createElement('a')
    link.href = isMobile ? pdf_mobile : pdf_desktop
    link.download = isMobile
      ? 'Manual Celular/Tablet - LIP.pdf'
      : 'Manual Computador - LIP.pdf'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const viewManual = () => {
    window.open(pdf_mobile, '_blank')
  }

  return (
    <Stack gap={4}>
      <Stack gap={4}>
        <Text
          textAlign="center"
          fontSize="2rem"
          fontWeight="bold"
          color="brand.primary.dark_2"
        >
          Manual
        </Text>
        <Text textAlign="center" fontSize="md" fontWeight="semibold">
          Bem-vindo/a! Esse é o Manual do LIP do PNLD. Ele foi feito para apoiar
          seu uso, mas fique atento, pois ele está em constante evolução. Busque
          sempre a última versão disponível! 😊
        </Text>
      </Stack>
      <Flex
        justify="center"
        align="center"
        gap={3}
        color="brand.primary.dark_1"
        px={6}
        py={4}
        borderBottom="4px solid"
        borderColor="brand.primary.dark_1"
      >
        <Icon as={isMobile ? FaMobileAlt : FaLaptop} />
        <Text fontWeight="semibold" fontSize="md">
          {isMobile ? 'Celular/Tablet' : 'Computador'}
        </Text>
      </Flex>
      <Button
        variant="solid-light"
        borderRadius="full"
        onClick={downloadManual}
      >
        Baixar manual em pdf
      </Button>
      {isMobile ? (
        <Button variant="solid-light" borderRadius="full" onClick={viewManual}>
          Visualizar manual
        </Button>
      ) : (
        <Flex flex={1}>
          <iframe src={pdf_desktop} width="100%" height="725px" />
        </Flex>
      )}
    </Stack>
  )
}

export default Manual
