import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import Home from '@/pages/Home'
import ContactForm from '@/pages/Home/ContactForm'
import Faq from '@/pages/Home/Faq'
import Manual from '@/pages/Home/Manual'
import Tutorials from '@/pages/Home/Tutorials'

class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route path="contato" element={<ContactForm />} />
            <Route path="duvidas" element={<Faq />} />
            <Route path="manual" element={<Manual />} />
            <Route path="tutoriais" element={<Tutorials />} />
            <Route path="/" element={<Navigate to="/contato" />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    )
  }
}

export default App
