import React, { createRef, useRef, useState } from 'react'

import { Formik, Form, FormikValues, ErrorMessage, FormikProps } from 'formik'
import * as yup from 'yup'

import {
  Text,
  Stack,
  Flex,
  SimpleGrid,
  Button,
  FormControl,
  FormLabel,
  useToast,
} from '@chakra-ui/react'

import { useMutationPostTicket } from '@/api/mutations'
import {
  Input,
  Select,
  Textarea,
  FileUpload,
  InputWithMask,
} from '@/components/Forms'
import { validateCPF } from '@/utils/validation'

const subjects = [
  { value: 'Cadastro', label: 'Cadastro' },
  { value: 'Leitura da Obra', label: 'Leitura da Obra' },
  { value: 'Biblioteca', label: 'Biblioteca' },
  { value: 'Estante', label: 'Estante' },
  { value: 'Perfil', label: 'Perfil' },
  { value: 'Acessibilidade', label: 'Acessibilidade' },
  { value: 'Outros', label: 'Outros' },
]

const categories = [
  { value: 'Dúvida', label: 'Dúvida' },
  { value: 'Sugestão', label: 'Sugestão' },
  { value: 'Reclamação', label: 'Reclamação' },
  { value: 'Elogio', label: 'Elogio' },
  { value: 'Outros', label: 'Outros' },
]

const userTypes = [
  { value: 'Aluno', label: 'Aluno' },
  { value: 'Professor', label: 'Professor' },
  { value: 'Pai/Responsável', label: 'Pai/Responsável' },
  { value: 'Diretor', label: 'Diretor' },
  { value: 'Analista', label: 'Analista' },
]

const platforms = [
  { value: 'Celular/Tablet', label: 'Celular/Tablet' },
  { value: 'Computador', label: 'Computador' },
]

const ContactForm = () => {
  const toast = useToast()
  const { mutate: postTicket, isLoading } = useMutationPostTicket({
    onSuccess: () => {
      formikRef.current?.resetForm()
      toast({
        title: 'Formulário enviado com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    },
    onError: () => {
      toast({
        title: 'Erro ao enviar formulário',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    },
  })

  const formikRef = useRef<FormikProps<FormikValues>>(null)

  const validationSchema = yup.object().shape({
    name: yup.string().required('Nome obrigatório'),
    cpf: yup
      .string()
      .required('CPF obrigatório')
      .test('test-cpf', 'O CPF está incompleto', value => {
        return !value.includes('_')
      })
      .test('test-cpf-valid', 'CPF inválido', value => {
        return validateCPF(value)
      }),
    email: yup.string().email('E-mail inválido').required('E-mail obrigatório'),
    telephone: yup
      .string()
      .required('Telefone obrigatório')
      .test('test-telephone', 'O telefone está incompleto', value => {
        return !value.includes('_')
      }),
    subject: yup.string().required('Assunto obrigatório'),
    category: yup.string().required('Categoria obrigatório'),
    user_type: yup.string().required('Tipo de usuário obrigatório'),
    platform: yup.string().required('Plataforma obrigatório'),
    content: yup.string().required('Conteúdo obrigatório'),
    file: yup.mixed(),
  })

  const handleSubmit = async (values: FormikValues) => {
    const payload = {
      name: `${values.user_type} - ${values.subject} (${values.category}) - ${values.platform}`,
      content: `Nome completo: ${values.name} | CPF: ${values.cpf} | E-mail: ${values.email} | Telefone: ${values.telephone} | Mensagem: ${values.content}`,
      files: values.file,
    }

    postTicket(payload)
  }

  return (
    <Stack gap={7}>
      <Stack gap={4}>
        <Text
          textAlign="center"
          fontSize="2rem"
          fontWeight="bold"
          color="brand.primary.dark_2"
        >
          Formulário de contato
        </Text>
        <Text textAlign="center" fontSize="md" fontWeight="semibold">
          Bem-vindo! Esse é o Formulário de Abertura de Chamado do LIP do PNLD,
          onde é possível pedir ajuda, tirar dúvidas e enviar sugestões ou
          reclamações. Após o registro, nossa equipe entrará em contato para
          solução.
        </Text>
      </Stack>
      <Formik
        innerRef={formikRef}
        initialValues={{
          name: '',
          cpf: '',
          email: '',
          telephone: '',
          subject: '',
          category: '',
          user_type: '',
          platform: '',
          content: '',
          file: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <Stack gap={4}>
            <Text fontWeight="bold" color="brand.primary.dark_2">
              Seus dados
            </Text>
            <SimpleGrid columns={{ base: 1, lg: 2 }} columnGap={7} rowGap={4}>
              <Input
                name="name"
                label="Nome completo"
                placeholder="Digite seu nome"
                inputProps={{ maxLength: 60 }}
              />
              <InputWithMask
                name="cpf"
                label="CPF"
                mask="999.999.999-99"
                placeholder="Digite seu CPF"
              />
              <Input
                name="email"
                label="E-mail"
                type="email"
                placeholder="Digite seu email"
                inputProps={{ maxLength: 60 }}
              />
              <InputWithMask
                name="telephone"
                label="Telefone"
                mask="(99) 99999-9999"
                placeholder="Digite seu telefone"
              />
            </SimpleGrid>
            <Text fontWeight="bold" color="brand.primary.dark_2">
              Sua mensagem
            </Text>
            <SimpleGrid columns={{ base: 1, lg: 4 }} spacing={7}>
              <Select
                name="subject"
                label="Assunto"
                placeholder="Assunto"
                options={subjects}
              />
              <Select
                name="category"
                label="Categoria"
                placeholder="Categoria"
                options={categories}
              />
              <Select
                name="user_type"
                label="Tipo de usuário"
                placeholder="Tipo de usuário"
                options={userTypes}
              />
              <Select
                name="platform"
                label="Plataforma"
                placeholder="Plataforma"
                options={platforms}
              />
            </SimpleGrid>
            <Textarea
              name="content"
              label="Conteúdo"
              placeholder="Escreva aqui..."
              helperText="Máximo de 500 caracteres"
              maxLength={500}
              inputProps={{ resize: 'none' }}
            />
            <FormControl>
              <FormLabel fontSize="sm" fontWeight="semibold">
                Anexar arquivos (opcional)
              </FormLabel>
              <FileUpload id="file" name="file" maxFileSize={15} isMultiple />
              <ErrorMessage name="file">
                {(message: string) => (
                  <Text color="red.600" fontSize="sm">
                    {message}
                  </Text>
                )}
              </ErrorMessage>
            </FormControl>
            <Flex justify="center">
              <Button
                type="submit"
                borderRadius="full"
                px={12}
                isLoading={isLoading}
              >
                Enviar formulário
              </Button>
            </Flex>
          </Stack>
        </Form>
      </Formik>
    </Stack>
  )
}

export default ContactForm
