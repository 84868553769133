import type { StyleFunctionProps } from '@chakra-ui/react'

const styles = {
  global: (props: StyleFunctionProps) => ({
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
      listStyle: 'none',
      textAlign: 'start',
    },
    'html, body, #__next': {
      minHeight: '100vh',
      background: props.colorMode === 'light' ? 'white' : 'black',
    },
  }),
}

export default styles
