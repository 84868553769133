import { styled, Link } from '@chakra-ui/react'

const NavItem = styled(Link, {
  baseStyle: {
    fontSize: 'md',
    fontWeight: 'semibold',
    bg: 'brand.primary.light_1',
    color: 'brand.primary.dark_2',
    borderRadius: 'full',
    px: 6,
    py: 2,
    textAlign: 'center',
    _activeLink: {
      bg: 'brand.primary.base',
    },
    _hover: {
      bg: 'brand.primary.light_2',
    },
  },
})

export default {
  NavItem,
}
