export const validateCPF = (cpf: string) => {
  cpf = cpf.replace(/\D/g, '')

  if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false

  let sum = 0,
    remainder

  for (let i = 1; i <= 9; i++) sum += parseInt(cpf[i - 1]) * (11 - i)

  remainder = (sum * 10) % 11
  if (remainder === 10 || remainder === 11) remainder = 0
  if (remainder !== parseInt(cpf[9])) return false

  sum = 0
  for (let i = 1; i <= 10; i++) sum += parseInt(cpf[i - 1]) * (12 - i)

  remainder = (sum * 10) % 11
  if (remainder === 10 || remainder === 11) remainder = 0

  return remainder === parseInt(cpf[10])
}
