import React from 'react'
import { Outlet } from 'react-router-dom'

import { Box, Flex } from '@chakra-ui/react'

import Sidebar from '@/components/Sidebar'

const Home = () => {
  return (
    <Flex
      minH="100vh"
      textAlign="center"
      fontSize="xl"
      flexDirection={{
        base: 'column',
        lg: 'row',
      }}
    >
      <Sidebar />
      <Flex
        ml={{ base: 0, lg: '27.5rem' }}
        flex={1}
        bg="brand.highlight.light"
        py={14}
        px={{
          base: 5,
          lg: 28,
        }}
      >
        <Box w="full">
          <Outlet />
        </Box>
      </Flex>
    </Flex>
  )
}

export default Home
