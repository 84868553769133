import React from 'react'
import { NavLink } from 'react-router-dom'

import { LinkProps } from '@chakra-ui/react'

import S from './styles'

interface INavItemProps extends LinkProps {
  name: string
  to: string
}

const NavItem = ({ name, to, ...rest }: INavItemProps) => {
  return (
    <S.NavItem as={NavLink} to={to} role="group" {...rest}>
      {name}
    </S.NavItem>
  )
}

export default NavItem
